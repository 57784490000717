import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <Link to="/">
        <h2>#buildforCause</h2>
      </Link>
      <p>
        We help nonprofit organizations with their software development needs - 
        Essentially with Web, Social Media, CRM and Fundraising services for free.
        <br />
        one Cause at a time!
      </p>
      <ul className="actions">
        <li>
          <Link to="/generic" className="button">
            Learn More
          </Link>
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact Us</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>Atlanta &bull; GA 30303 &bull; USA</dd>
        <dt>Phone</dt>
        <dd>(404) 941-4116</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:hi@buildforcause.com">hi@buildforcause.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/buildforcause"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://facebook.com/buildforcause" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/buildforcause" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/buildforcause" className="icon fa-github alt">
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://wa.me/14049414116" className="icon fa-whatsapp alt">
            <span className="label">WhatsApp</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      2020 &copy; buildforCause. Design: <a href="https://html5up.net">HTML5 UP</a>.
    </p>
  </footer>
)

export default Footer
